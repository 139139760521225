@font-face {
  font-family: 'ACaslonPro';
  src: local('ACaslonPro'), url(./fonts/ACaslonPro-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Mark';
  src: local('Mark'), url(./fonts/Mark-Heavy.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Mark';
  src: local('Mark'), url(./fonts/Mark-Medium.ttf) format('truetype');
  font-weight: normal;
}

* {
  box-sizing: border-box;
}

.info {
  margin-top: 50%;
}

.legend {
  position: absolute;
  background: #555;
  height: 310px;
  bottom: 10px;
  left: 259px;
  display: flex;
  z-index: 1;
  padding: 5px;
  justify-content: center;

  img {
    margin-top: auto;
    margin-bottom: 100px;
    background-color: #555;
  }
}

.error-message {
  display: flex;
  height: 100vh;

  h1 {
    margin: auto;
  }
}

.App {
  text-align: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  font-family: Mark, Helvetica, sans-serif;
  overflow: hidden;
}

.helpIcon {
  position: absolute;
  height: 64px;
  width: 64px;
  padding-top: 2px;
  top: 25px;
  right: 25px;
  z-index: 1;
  pointer-events: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);

  img {
    object-fit: contain;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }
}

.sidebar {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  position: relative;
  margin: 0px;
  padding-right: 5px;

  h1,
  h2 {
    text-align: left;
    padding-left: 5px;
    margin: 0;
    font-weight: 600;
  }

  h1 {
    margin-bottom: 40px;
    font-family: ACaslonPro, Helvetica, serif;
  }

  select,
  input {
    outline: none;
    border: none;
    background: #eee;
    padding: 5px;
    font-family: inherit;
    width: 100%;
  }

  select {
    font-weight: bold;
    font-size: 14px;
  }

  .search-wrapper {
    position: relative;
    background-color: #eee;
    display: flex;
    padding: 3px;

    input {
      flex-grow: 1;
    }

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      color: grey;
      width: 25px;
      background-image: url("./magnify.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .subheading {
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .tenant-list {
    overflow-y: auto;
    margin: 5px 0px;
    padding-bottom: 50px;

    .tenant-list-button {
      display: none;
    }

    &::-webkit-scrollbar {
      width: .5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: .25em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: .25em;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 50px;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }

    &.hide-categories {
      .tenant-button {
        border-top: solid 1px #333 !important;
      }

      .category-heading {
        display: none;
      }

      .category-listings:first-of-type {
        .tenant-button:first-child {
          border: none !important;
        }
      }
    }
  }

  .category-listings {
    display: block;

    .tenant-button {
      all: unset;
      display: block;
      padding: 10px;
      outline: none;
      border: none;
      border-top: solid 1px #333;
      text-align: left;
      font-size: 14px;
      font-family: inherit;
      width: calc(100% - 20px);
      background-color: #ddd;

      &.active {
        background-color: #bbb;
      }

      &:first-child {
        border: none;
      }
    }
  }

  .category-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    padding: 10px;
    text-align: left;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

.read-more {
  display: flex;
  padding: 10px;
  align-items: center;
  font-size: 12px;
  background-color: #bbb;

  a {
    margin-left: auto;
    font-size: 12px;
    text-decoration: none;
    color: #333;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    height: 10px;
    width: 10px;
    margin-left: 2px;
  }
}

.map3d-container {
  max-width: unset !important;
  position: relative;
  margin: 0 !important;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  .northIcon {
    position: absolute;
    bottom: 30px;
    left: 30px;
    border-radius: 50%;
    background: white;
    width: 60px;
    height: 60px;
    padding: 10px;
    z-index: 2;

    img {
      height: 40px;
      width: 40px;
      object-fit: contain;
    }

    &::after {
      display: none;
    }
  }
}

.flat-flyout {
  color: white !important;
  background-color: black !important;
  white-space: nowrap;
  span {
    color: inherit !important;
  }

  &::after {
    border-top: 20px solid black !important;
  }

  &.amenityLabel {
    background: none !important;
  }
}

#renderCanvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.map3d-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 101;
  display: flex;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  pointer-events: none;

  &.isLoading {
    opacity: 1;
  }

  & > * {
    margin: auto;
  }
}

.App.simple {
  .sidebar {
    display: none;
  }

  .legend {
    left: 10px;
  }
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 10px;
    height: unset;
    display: grid;
    grid-template-areas: 
      'shop floor'
      'tenant tenant';
    gap: 10px;
    position: absolute;
    background-color: white;
    z-index: 2;
    left: 0;

    h2 {
      font-size: 18px;
    }

    .search-section {
      grid-area: shop;
    }

    .floor-section {
      grid-area: floor;

      select {
        display: block;
        width: 100%;
        height: 33px;
      }
    }

    .tenant-list {
      grid-area: tenant;
      width: 100%;
      padding-bottom: 0;
      overflow: visible;
      position: relative;

      .category-heading {
        overflow-x: visible;
      }

      .tenant-list-button {
        height: 33px;
        width: 100%;
        outline: none;
        border: none;
        font-family: inherit;
        font-size: 14px;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: left;
        position: relative;
        flex: 1;

        &::after {
          content: '▼';
          position: absolute;
          right: 10px;
          top: 8px;
        }

        &.down::after {
          content: '▲'
        }
      }

      .tenant-list-wrap {
        display: none;

        &.show {
          display: flex;
          flex-direction: column;
          position: absolute;
          flex-basis: auto;
          left: 0;
          right: 0;
          top: 33px;
          z-index: 2;
          max-height: calc(100vh - 166px);
          overflow-y: scroll;
          flex: 1;
        }
      }

      &::after {
        display: none;
      }
    }
    h1 {
      display: none;
    }
  }

  .map3d-container {
    max-height: 100vh;
  }

  .helpIcon {
    top: 175px;
    right: 15px;
    width: 32px;
    height: 32px;
  }

  .legend {
    left: 10px;
  }
}
